document.addEventListener('DOMContentLoaded', () => {
    const buttons = document.querySelectorAll('.button-copy');

    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const textToCopy = button.getAttribute('data-text');

        if (textToCopy) {
          navigator.clipboard.writeText(textToCopy)
            .then(() => {
              button.classList.add('copied');
              const originalText = button.textContent;
              button.textContent = 'Скопійовано';

              setTimeout(() => {
                button.classList.remove('copied');
                button.textContent = originalText;
              }, 3000);
            })
            .catch(err => {
              console.error('Помилка при копіюванні тексту:', err);
            });
        } else {
          console.warn('Атрибут data-text відсутній або порожній');
        }
      });
    });
  });
